<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>إضافة تاجر جديد</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="mt-6 px-6">
        <vs-input
          label="اسم المستخدم"
          data-vv-as="إسم المستخدم"
          v-model="new_user.name"
          class="mt-5 w-full"
          v-validate="'required|max:50'"
          name="name"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first("name")
        }}</span>

        <vs-input
          label="رقم الهاتف"
          data-vv-as="رقم الهاتف"
          v-model="new_user.phone"
          class="mt-5 w-full"
          v-validate="'required|length:10'"
          placeholder="0910001122"
          name="phone"
        />
        <span class="text-danger text-sm" v-show="errors.has('phone')">{{
          errors.first("phone")
        }}</span>

        <vs-input
          label="كلمة المرور"
          data-vv-as="كلمة المرور"
          type="password"
          v-model="new_user.password"
          class="mt-5 w-full"
          v-validate="'required'"
          ref="password"
          name="password"
        />
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>

        <vs-input
          label="تأكيد كلمة المرور"
          data-vv-as="تأكيد كلمة المرور"
          type="password"
          v-model="new_user.password_confirmation"
          class="mt-5 w-full"
          v-validate="'required|confirmed:password'"
          name="password_confirmation"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('password_confirmation')"
          >{{ errors.first("password_confirmation") }}</span
        >

        <div class="flex items-center vs-row mt-5" >
          <div class="vs-col flex" id="images" v-if="new_user.image.length">
            <img
              v-if="new_user.image[0].blob"
              :src="new_user.image[0].blob"
              @click="spliceFile(index, 'admin_image')"
              style="max-width: none; max-height: 60px"
            />
            <img v-else :src="new_user.image[0].path" />
          </div>
          <file-upload
            v-if="!new_user.image.length && isSidebarActive"
            ref="upload"
            v-model="new_user.image"
            class="vs-button vs-col bg-primary"
            post-action="/post.method"
            put-action="/put.method"
            extensions="jpg,gif,png,webp"
            :maximum="1"
            @input-filter="inputFilter"
          >
            تنزيل صورة
          </file-upload>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >حفظ</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >إلغاء</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VueUploadComponent from "vue-upload-component";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "file-upload": VueUploadComponent,
    VuePerfectScrollbar,
  },
  data() {
    return {
      new_user: {
        name: "",
        phone: "",
        image: [],
        password: "",
        password_confirmation: "",
      },
      settings: {
        // perfectScrollbar settings
        maxScrollbarLength: 30,
        wheelSpeed: 1,
        swipeEasing: true,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.$validator.reset();
      }
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any();
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    initValues() {
      this.new_user = {
        name: "",
        phone: "",
        image: [],
        password: "",
        password_confirmation: "",
      }
    },
    ...mapActions("stores", ["createStoreAdmin"]),
    spliceFile(index) {
      this.new_user.image.splice(index, 1);
    },
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpg|png)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "خطأ",
            text: "يجب ان تكون الصورة من الامتداد jpeg او png",
            color: "danger",
          });
          return prevent();
        }
        if (newFile.size / 1024.0 > 5000) {
          this.$vs.notify({
            title: "خطأ",
            text: "يجب ان تكون الصورة اصغر من 2 mb",
            color: "danger",
          });
          return prevent();
        }
      }
      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    submitData() {
      this.$vs.loading();
      this.$validator.validateAll().then((result) => {
        if (this.new_user.image.length <= 0) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اضافة صورة",
            color: "danger",
          });
        } else if (result) {
          let data = new FormData();
          data.set("name", this.new_user.name);
          data.set("phone", this.new_user.phone);
          data.set("image", this.new_user.image[0].file);
          data.set("password", this.new_user.password);
          data.set(
            "password_confirmation",
            this.new_user.password_confirmation
          );

          this.createStoreAdmin(data)
            .then((response) => {
              this.$emit("pushNewAdminData", response.data.data); //gets the recieved respnose from the request and passes it back to the parent component
              this.$vs.loading.close();
              this.$emit("closeSidebar");
              this.initValues()
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم إضافة مشرف جديد لهذا المتجر",
                color: "success",
              });
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.errorDialog(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#preview-image {
  max-width: 30%;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
