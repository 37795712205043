<template>
  <div id="data-list-list-view" class="data-list-container">
    <seller-add
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />
    <vs-popup
      class="holamundo"
      title="تعيين مشرف لهذا المتجر"
      :active.sync="popupActive"
    >
      <div
        class="popup-style w-full h-full flex flex-col justify-between"
        style="height: 300px !important"
      >
        <div>
          <label for="status" class="w-full mb-1">رقم الهاتف</label>
          <v-select
            name="userphone"
            data-vv-scope="addAdmin"
            data-vv-as="رقم الهاتف"
            v-validate="'required'"
            v-model="select_admin"
            label="phone"
            :filterable="false"
            :options="admins"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
          >
            <li slot="list-footer" class="pagination flex justify-end">
              <vs-button
                type="border"
                icon-pack="feather"
                icon="icon-arrow-right"
                :disabled="!next_page_url"
                @click="getAdmins(next_page_url)"
                size="small"
              ></vs-button>
              <vs-button
                icon-pack="feather"
                icon="icon-arrow-left"
                type="border"
                :disabled="!prev_page_url"
                @click="getAdmins(prev_page_url)"
                size="small"
              ></vs-button>
            </li>
          </v-select>
          <span
            class="text-danger text-center text-sm"
            v-show="errors.has('addAdmin.userphone')"
            >{{ errors.first("addAdmin.userphone") }}</span
          >
        </div>
        <div class="w-full text-center">
          <vs-button
            class="rounded mx-3"
            color="danger"
            type="border"
            @click="togglePopup"
            >إلغاء</vs-button
          >
          <vs-button class="rounded mx-3" @click="addAdmin" color="primary"
            >تعيين</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <vx-card title="تعديل بيانات متجر">
      <h4 class="mt-8">صورة المتجر</h4>
      <div class="vx-row bottom-seperator">
        <div class="vx-col my-8 w-full mb-6 flex items-center">
          <div class="con-img-upload flex" id="images" v-if="store.logo.length">
            <div
              class="img-upload"
              v-for="(image, index) in store.logo"
              :key="index"
            >
              <button
                type="button"
                class="btn-x-file"
                @click="spliceFile(index, 'logo')"
              >
                <i translate="translate" class="material-icons notranslate"
                  >clear</i
                >
              </button>
              <img
                v-if="image.blob"
                :src="image.blob"
                style="max-width: none; max-height: 100%"
              />
              <img v-else :src="image" class="w-full" />
            </div>
          </div>
          <file-upload
            v-if="store.logo.length < 1"
            ref="upload"
            v-model="store.logo"
            class="add-image-btn flex"
            post-action="/post.method"
            put-action="/put.method"
            extensions="jpg,gif,png,webp"
            :maximum="1"
            @input-filter="inputFilter"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
          </file-upload>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col my-3 sm:w-1/2 w-full">
          <div>
            <div class="vx-row">
              <div class="vx-col my-3 sm:w-1/2 w-full">
                <div class="mb-2"><label for="">إسم المتجر</label></div>
                <vs-input
                  v-validate="'required'"
                  data-vv-scope="store"
                  name="name"
                  data-vv-as="إسم المتجر"
                  class="w-full"
                  v-model="store.name"
                />
                <span
                  class="text-danger text-center text-sm"
                  v-show="errors.has('store.name')"
                  >{{ errors.first("store.name") }}</span
                >
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full">
                <div class="mb-2"><label for="">المدينة</label></div>
                <v-select
                  :options="cities"
                  v-model="store.city"
                  label="name"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-validate="'required'"
                  data-vv-scope="store"
                  name="city"
                  data-vv-as="المدينة"
                  class="w-full"
                ></v-select>
                <span
                  class="text-danger text-center text-sm"
                  v-show="errors.has('store.city')"
                  >{{ errors.first("store.city") }}</span
                >
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full">
                <div class="mb-2"><label for="">رقم الهاتف</label></div>
                <vs-input
                  v-validate="'required|length:10'"
                  data-vv-scope="store"
                  name="store_phone"
                  data-vv-as="رقم المتجر"
                  type="number"
                  placeholder="0920000000"
                  class="w-full"
                  v-model="store.phone"
                />
                <span
                  class="text-danger text-center text-sm"
                  v-show="errors.has('store.store_phone')"
                  >{{ errors.first("store.store_phone") }}</span
                >
              </div>
              <div class="vx-col mt-3 w-full mb-6">
                <label for="">وصف المتجر</label>
                <vs-textarea
                  counter="250"
                  v-validate="'required'"
                  data-vv-scope="store"
                  name="description"
                  data-vv-as="وصف المتجر"
                  class="mt-3"
                  rows="4"
                  placeholder="وصف قصير للمتجر"
                  v-model="store.description"
                />
                <span
                  class="text-danger text-center text-sm"
                  v-show="errors.has('store.description')"
                  >{{ errors.first("store.description") }}</span
                >
              </div>
              <div class="vx-col w-full">
                <h3 class="my-4">خيارات</h3>
                <div class="my-3 flex">
                  <vs-switch v-model="store.class_a_access" />
                  <label class="mx-4" for="">إضافة المنتجات إلي class-a</label>
                </div>
                <div class="my-3 flex">
                  <vs-switch v-model="store.is_featured" />
                  <label class="mx-4" for=""
                    >تمييز المنتجات في الصفحة الرئيسية</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- product descreption -->
        <div class="vx-col my-3 sm:w-1/2 w-full seperator">
          <vs-list>
            <vs-list-header title="إدارة المشرفين"></vs-list-header>
            <span class="text-warning"
              >ملاحظة/ يجب التأكد من أن المشرف غير معين لمتجر آخر قبل
              إضافته.</span
            >
            <vs-list-item
              v-for="(admin, index) in store.admins"
              :key="index"
              :title="admin.name"
              :subtitle="admin.phone"
            >
              <vs-button @click="deleteAdmin(index, admin.id)" color="danger"
                >إزالة</vs-button
              >
            </vs-list-item>
            <vs-list-item title="هل تريد إضافة مشرف لهذا المتجر؟">
              <vs-button color="success" @click="togglePopup">حالي</vs-button>
              <vs-button color="success" @click="addNewData" class="ml-3"
                >جديد</vs-button
              >
            </vs-list-item>
          </vs-list>
        </div>
      </div>
      <!-- submitting -->
      <div class="vx-row mt-8 btn-alignment">
        <div class="vx-col sm:w-full w-full mb-6">
          <vs-button
            class="mr-5 text-lg py-4"
            @click="$router.push({ name: 'stores' })"
            color="warning"
            type="border"
            >إلغاء</vs-button
          >
          <vs-button
            class="mr-5 text-lg px-16 py-4"
            @click="submit"
            color="success"
            type="filled"
            >تعديل</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import SellerAdd from "../../users-management/sellers/AddNewUser.vue";
import vSelect from "vue-select";
import VueUploadComponent from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  components: {
    vSelect,
    "file-upload": VueUploadComponent,
    SellerAdd,
  },
  data() {
    return {
      addNewDataSidebar: false,
      popupActive: false,
      sidebarData: {},
      select_admin: "",
      admins: [],
      prev_page_url: "",
      next_page_url: "",
      store: {
        deletedAdmins: [],
        phone: "",
        location: {
          lat: "",
          lng: "",
        },
        name: "",
        city_id: 1,
        is_featured: false,
        class_a_access: false,
        admins: [],
        description: "",
        logo: [],
      },
    };
  },
  computed: {
    cities() {
      return this.$store.state.stores.cities;
    },
  },
  methods: {
    ...mapActions("stores", ["fetchStore", "updateStore", "fetchCities"]),
    ...mapActions("users", ["fetchStoreAdmins"]),
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    togglePopup() {
      this.popupActive = !this.popupActive;
    },
    addAdmin() {
      this.$validator.validateAll("addAdmin").then((result) => {
        if (result) {
          this.store.admins.push(this.select_admin);
          this.togglePopup();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم إضافة مشرف جديد لهذا المتجر",
            color: "success",
          });
        }
      });
    },
    deleteAdmin(index, id) {
      if (this.store.admins.length >= 2) {
        this.store.admins.splice(index, 1);
        this.deletedAdmins.push(id);
      } else {
        this.$vs.dialog({
          title: "خطأ في الحذف",
          text: "لا يمكنك حذف جميع المشرفين, يجب إدخال مشرف واحد على الأقل.",
          color: "danger",
          acceptText: "إغلاق",
        });
      }
    },
    spliceFile(index) {
      this.store.logo.splice(index, 1);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile.size / 1024.0 > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    getAdmins(link) {
      let page = link.split("=")[1];
      this.fetchStoreAdmins(page).then((response) => {
        this.admins = response.data.data.data;
        this.next_page_url = response.data.data.next_page_url;
        this.prev_page_url = response.data.data.prev_page_url;
      });
    },
    submit() {
      this.$vs.loading();
      this.$validator.validateAll("store").then((result) => {
        if (this.store.admins.length <= 0) {
          this.$vs.loading.close();
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "الرجاء إضافة مشرف لهذا المتجر",
            color: "danger",
            acceptText: "إغلاق",
          });
        }
        else if (this.store.logo.length <= 0) {
          this.$vs.loading.close();
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "يجب اضافة صورة للمتجر",
            color: "danger",
            acceptText: "إغلاق",
          });
        } else if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("_method", "put");
          bodyFormData.set("id", this.$route.params.id);
          bodyFormData.set("name", this.store.name);
          bodyFormData.set("phone", this.store.phone);
          bodyFormData.set("city_id", this.store.city.id);
          bodyFormData.set("description", this.store.description);
          bodyFormData.set("longitude", this.store.location.lng + "1");
          bodyFormData.set("latitude", this.store.location.lat + "1");
          bodyFormData.set("class_a_access", this.store.class_a_access ? 1 : 0);
          bodyFormData.set("is_featured", this.store.is_featured ? 1 : 0);
          if(this.store.logo[0].file){
            bodyFormData.set("logo", this.store.logo[0].file);
          }
          if (this.store.deletedAdmins.length) {
            this.store.deletedAdmins.forEach((admin, index) => {
              bodyFormData.append("admins_ids[" + admin.id + "]", "");
            });
          }
          this.store.admins.forEach((admin, index) => {
            bodyFormData.append("admins_ids[" + index + "]", admin.id);
          });

          this.updateStore(bodyFormData)
            .then(() => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم إنشاء المتجر بنجاح",
                color: "success",
              });
              this.$router.push({
                name: "stores",
              });
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.errorDialog(error);
            });
        }
      });
    },
  },
  mounted() {
    this.fetchStore(this.$route.params.id).then((response) => {
      this.store.id = response.data.data.id;
      this.store.phone = response.data.data.phone;
      this.store.location.lat = Number(response.data.data.latitude);
      this.store.location.lng = Number(response.data.data.longitude);
      this.store.name = response.data.data.name;
      this.store.city = response.data.data.city;
      this.store.is_featured = response.data.data.is_featured ? true : false;
      this.store.class_a_access = response.data.data.class_a_access
        ? true
        : false;
      this.store.admins = response.data.data.users;
      this.store.description = response.data.data.description;
      this.store.logo.push(response.data.data.logo);
      this.store.is_visible = response.data.data.is_visible;
    });
  },
  created() {
    this.fetchStoreAdmins(1).then((response) => {
      this.admins = response.data.data.data;
      this.next_page_url = response.data.data.next_page_url;
      this.prev_page_url = response.data.data.prev_page_url;
    });
    this.fetchCities();
  },
};
</script>

<style>
.con-img-upload {
  width: auto !important;
}

.add-image-btn {
  height: 200px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #f2edeb;
}
</style>